import React from "react";
import Header from "components/Header/Header";
import Top from "components/Top/Top";
import Main from "components/Main/Main";
import TabBar from "components/TabBar/TabBar";
import { log } from "console";

const Home = () => {
  const getCookieValue = (cookieName: string) => {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const downLoadApp = () => {
    const fbcValue = getCookieValue("_fbc") || "";
    const fbpValue = getCookieValue("_fbp") || "";
    const urlParams = new URLSearchParams(window.location.search);
    // const p0 = urlParams.get("p0");
    // const p1 = urlParams.get("p1");
    // const p2 = urlParams.get("p2");
    // const p3 = urlParams.get("p3");
    // const p4 = urlParams.get("p4");
    // const p5 = urlParams.get("p5");
    // const p6 = urlParams.get("p6");

    const af_siteid = urlParams.get("af_siteid");
    const c = urlParams.get("c");
    const af_c_id = urlParams.get("af_c_id");
    const af_adset_id = urlParams.get("af_adset_id");
    const af_ad_id = urlParams.get("af_ad_id");
    const af_adset = urlParams.get("af_adset");
    const af_ad = urlParams.get("af_ad");  
    const af_click_lookback = urlParams.get("af_click_lookback"); 
    const clickid = urlParams.get("clickid"); 
    const advertising_id = urlParams.get("advertising_id"); 
    const ad_ext = urlParams.get("ad_ext"); 
    const af_ip = urlParams.get("af_ip"); 

    const webSrc = sessionStorage.getItem("src");
    const encodeSrc = window.btoa(webSrc);
    // console.log("webSrc=", webSrc);
    let src = `https://playing.onelink.me/UST7/htd6r3zn?af_js_web=true&af_ss_ver=2_7_3&af_siteid=${af_siteid}&af_c_id=${af_c_id}&af_adset_id=${af_adset_id}&af_ad_id=${af_ad_id}&af_adset=${af_adset}&af_ad=${af_ad}&af_click_lookback=${af_click_lookback}&clickid=${clickid}&advertising_id=${advertising_id}&ad_ext=${ad_ext}&af_ip=${af_ip}&deep_link_value=${encodeSrc}&af_ss_ui=true`;
    // let src = `https://playing.onelink.me/UST7?af_js_web=true&af_ss_ver=2_7_3&af_siteid=${af_siteid}&c=${c}&af_c_id=${af_c_id}&af_adset_id=${af_adset_id}&af_ad_id=${af_ad_id}&af_adset=${af_adset}&af_ad=${af_ad}&af_click_lookback=${af_click_lookback}&clickid=${clickid}&advertising_id=${advertising_id}&ad_ext=${ad_ext}&af_ip=${af_ip}&deep_link_value=${encodeSrc}&af_ss_ui=true`;
    // console.log("src=",src);
    
    const link = document.createElement("a");
    link.href = src;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Header downLoadApp={downLoadApp} />
      <Top downLoadApp={downLoadApp} />
      <Main downLoadApp={downLoadApp} />
      <TabBar downLoadApp={downLoadApp} />
    </div>
  );
};

export default Home;
